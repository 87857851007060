import React from 'react'

import { Article } from '@components'
import SEO from '../components/SEO'
import Section from '../components/Section'

export default function Page() {
  return (
    <>
      <SEO title="Personvern" />
      <Section type="grid">
        <Article
          title="Personvern"
          preamble="Disse vilkårene gjelder fra 1. oktober 2023. Foodsteps AS kan fritt endre vilkårene for bruk, kjøp og personvern. Oppdatert personvernerklæring vil alltid være tilgjengelig fra denne siden."
          toc
        >
          <Article.Text>
          Vi er opptatt av å ta godt vare på informasjonen om deg. Her kan du lese om hvilke data vi samler inn og hvordan det brukes.
          </Article.Text>

          <Article.Section title="Personopplysninger" id="personopplysninger">
            <Article.Text>
              Personopplysninger er opplysninger som kan knyttes direkte til deg
              som person, for eksempel navn og e-postadresse. Slike opplysninger
              er nødvendig for at vi skal kunne gi deg en best mulig
              kundeopplevelse.
            </Article.Text>
          </Article.Section>

          <Article.Section
            title="Behandlingsansvarlig og Databehandler"
            id="behandlingsansvarlig"
          >
            <Article.Text>
            Foodsteps AS, organisasjonsnummer 920 889 956 og adresse Kittel-Nielsens vei 61 B 1163 Oslo, er behandlingsansvarlig for dataene som samles inn. Foodsteps kan benytte eksterne databehandlere for å bistå med å levere tjenester til deg.
            </Article.Text>
            {/* <Article.Text>
              Foodsteps bruker Behalf AS som underleverandør. Behalf har ansvar
              for drift, vedlikehold og utvikling av tjenesten og behandler
              derfor personopplysninger som databehandler på vegne av Foodsteps.
              Foodsteps og Behalf har en databehandleravtale som sier hvilke
              opplysningere som behandles og hvem som har tilgang til hva.
            </Article.Text> */}
          </Article.Section>

          <Article.Section title="Samtykke" id="samtykke">
            <Article.Text>
            Det er frivillig å gi fra seg personopplysninger til Foodsteps, men for å kunne bruke tjenesten må du registrere deg med navn, e-postadresse og fødselsdato. Når du registrerer deg som kunde hos Foodsteps samtykker du til at du godtar vår personvernerklæring.
            </Article.Text>
          </Article.Section>

          <Article.Section title="Dine data og bruken av dem" id="dine-data">
          <Article.Text>
          Data som samles inn lagres via Amazon Web Services og Google Firebase. Alle data er anonymisert. Vi har på ingen måte muligheten til å identifisere hvem du er.

            </Article.Text>
            <Article.Text>
              Når du registrerer deg på Foodsteps og bruker tjenesten samler vi
              inn disse dataene om deg:
            </Article.Text>
            <Article.List
              items={[
                <>
                  <Article.SubTitle type="h3">
                    Navn og e-postadresse i vår kundebase:
                  </Article.SubTitle>
                  Dette bruker vi for å administrere kundeforholdet, herunder å varsle om endringer i abonnementet, eventuell nedetid o.l. Dersom du har samtykket til at vi kan sende deg nyhetsbrev vil vi bruke din e-postadresse til dette.
                </>,
                <>
                  <Article.SubTitle type="h3">
                    Facebook bruker-ID:
                  </Article.SubTitle>
                  Hvis du velger å logge inn med Facebook, kobles din konto hos Foodsteps til din profil på Facebook. Vi lagrer kun din IDen fra Facebook, og sender ikke noen informasjon tilbake til Facebook.
                </>,
                 <>
                 <Article.SubTitle type="h3">
                 GOOGLE BRUKER-ID:
                 </Article.SubTitle>
                 Hvis du velger å logge inn med Google, kobles din konto hos Foodsteps til din profil på Google. Vi lagrer kun din IDen fra Google og sender ikke noen informasjon tilbake til Google.
               </>,
                <>
                <Article.SubTitle type="h3">
                APPLE BRUKER-ID:
                </Article.SubTitle>
                Hvis du velger å logge inn med Apple, kobles din konto hos Foodsteps til din profil på Apple. Vi lagrer kun din IDen fra Apple, og sender ikke noen informasjon tilbake til Apple.
              </>,
                <>
                  <Article.SubTitle type="h3">
                    Data om det du gjør i appen:
                  </Article.SubTitle>
                  For at appen skal fungere må vi spare på en del informasjon
                  som oppskrifter du merker som favoritt, hvor langt du har
                  kommet i kursvideoer, ting du har lagt til i handlekurven og
                  lignende.
                </>,
                <>
                  <Article.SubTitle type="h3">
                    Anonymisert informasjon om hvordan du bruker appen og
                    nettsiden:
                  </Article.SubTitle>
                  Vi bruker Google Analytics og andre verktøy ved behov for å lære om, forbedre og videreutvikle brukeropplevelsen. De forteller oss for eksempel hvilke sider som er mest besøkt og hvor lenge besøkene varer
                </>,
                <>
                  <Article.SubTitle type="h3">Vipps:</Article.SubTitle>
                  Ved betaling gjennom Vipps sparer vi på informasjon som er
                  nødvendig for å sjekke status på betaling og ta betalt i
                  forbindelse med fornying av abonnementet, men lagrer ingen
                  informasjon om hvordan du betaler eller kortdetaljer.
                </>,
                <>
                  <Article.SubTitle type="h3">App Store:</Article.SubTitle>
                  Ved betaling gjennom App Store sparer vi på informasjon som er
                  nødvendig for å sjekke status på betaling, men lagrer ingen
                  informasjon om hvordan du betaler eller kortdetaljer.
                </>,
                <>
                  <Article.SubTitle type="h3">Google Play:</Article.SubTitle>
                  Ved betaling gjennom Google Play sparer vi på informasjon som
                  er nødvendig for å sjekke status på betaling, men lagrer ingen
                  informasjon om hvordan du betaler eller kortdetaljer.
                </>,
                // <>
                //   <Article.SubTitle type="h3">Cookies:</Article.SubTitle>
                //   Vi benytter informasjonskapsler for å gi deg bedre tjenester.
                //   De fleste nyere nettlesere er innstilt slik at de automatisk
                //   aksepterer cookies. Dersom du ikke ønsker dette, kan du endre
                //   innstillinger rett i nettleseren. På Nettvett.no kan du lese
                //   om hvordan du kan stille inn nettleseren din for å akseptere
                //   eller avvise informasjonskapsler.
                // </>,
                <>
                  <Article.SubTitle type="h3">Analyse:</Article.SubTitle>
                  Analyseverktøyet Google Analytics setter to
                  informasjonskapsler til bruk i analyse av bruksmønstre, disse
                  kapslene begynner på _ga og _gid.
                </>,
              ]}
            />
          </Article.Section>

          <Article.Section title="Rettslig grunnlag" id="rettslig-grunnlag">
            <Article.Text>
            Det rettslige grunnlaget for behandlingen av personopplysningene er Foodsteps berettigede interesse i å kunne administrere tjenesten til deg (GDPR artikkel 6 nr. 1, bokstav f). Vi samler ikke inn personopplysninger som ikke er relevant og nødvendig for å levere Foodsteps-appen til deg som kunde. Vi selger aldri personopplysningene dine videre til andre selskaper.
            </Article.Text>
          </Article.Section>

          <Article.Section title="Hvilke rettigheter har du?" id="rettigheter">
            <Article.List
              items={[
                <>
                  <Article.SubTitle type="h3">Tilgang:</Article.SubTitle>
                  Du har til enhver tid rett til å be om informasjon om
                  personopplysningene vi oppbevarer om deg. Kontakt oss på
                  post@foodsteps.no så hjelper vi deg.
                </>,
                <>
                  <Article.SubTitle type="h3">Korrigering:</Article.SubTitle>
                  Du har rett til å be om korrigering av personopplysningene dine dersom de ikke er korrekte, og dette inkluderer retten til å utfylle ufullstendige personopplysninger. Siden Foodsteps er en digital tjeneste kan du selv korrigere opplysningene inne i din brukerprofil.
                </>,
                <>
                  <Article.SubTitle type="h3">
                    Dataportabilitet:
                  </Article.SubTitle>
                  Du har rett til å få med deg dataene vi har lagret om deg hvis
                  du slutter å bruke Foodsteps. Kontakt oss på post@foodsteps.no
                  så hjelper vi deg.
                </>,
                <>
                  <Article.SubTitle type="h3">Sletting:</Article.SubTitle>
                  Personopplysningene vi har lagret om deg slettes når ditt
                  abonnement avsluttes. Personopplysninger direkte knyttet til
                  transaksjoner må vi imidlertid lagre i 5 kalenderår for å
                  oppfylle bokføringsloven. Opplysninger vil eventuelt også
                  kunne bli lagret lenger om nødvendig for å fastsette, gjøre
                  gjeldende eller forsvare rettskrav. Du har for øvrig rett til
                  når som helst å kreve sletting av personopplysningene som
                  behandles av Foodsteps, samt til å protestere mot behandlingen
                  og eventuelt påklage behandlingen til Datatilsynet.
                </>,
                 <>
                 Annonser
 Google
 Vi bruker infrastruktur- og annonsetjenester fra Google. For å finne informasjon om Googles tjenester og hvordan du kan påvirke Google sin bruk av personopplysninger, gå til policies.google.com. Dersom du har en Google-konto, kan du også endre dine personlige annonseinnstillinger på din google-konto. (Link to https://myaccount.google.com/)
 Samarbeid/kampanjer
 Foodsteps samarbeider med utvalgte aktører og gir dem mulighet til å dele tilbud, produkter og/eller informasjon til deg som bruker gjennom Foodsteps-appen. Aktørene og deres innhold er godkjent av Foodsteps. Dersom brukeren går inn på aktørens nettside gjennom eksterne lenke, har ikke Foodsteps ansvar for innholdet som vises i sidene.
                 </>
              ]}
            />
          </Article.Section>
        </Article>
      </Section>
    </>
  )
}
